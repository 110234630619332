import React, { useState, useEffect } from 'react';

function Countdown() {
  const [days, setDays] = useState('00');
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const endOfYear = new Date('December 31, 2025 13:59:59');
      const totalSeconds = Math.floor((endOfYear - now) / 1000);

      if (totalSeconds > 0) {
        const daysLeft = Math.floor(totalSeconds / (60 * 60 * 24));
        const hoursLeft = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutesLeft = Math.floor((totalSeconds % (60 * 60)) / 60);
        const secondsLeft = Math.floor(totalSeconds % 60);

        setDays(formatNumber(daysLeft));
        setHours(formatNumber(hoursLeft));
        setMinutes(formatNumber(minutesLeft));
        setSeconds(formatNumber(secondsLeft));
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatNumber = (num) => {
    return num < 10 ? '0' + num : num;
  };

  const pluralize = (num, label) => {
    return num > 1 ? label + 's' : label;
  };

  return (
    <div className="Animation-option flex md:flex-row md:justify-center flex-col md:space-x-10 md:space-y-0 space-y-10 px-10 my-20">
      <div className="text-3xl font-bold md:w-[300px] text-white">
        COMPTEZ CHAQUE SECONDE JUSQU'À{" "}
        <span className="text-[#EE7924]">L'ÉVÉNEMENT</span>
      </div>
      <div className="box-rebours flex sm:flex-row flex-col items-center md:space-x-10 sm:space-x-6 space-x-0 sm:space-y-0 space-y-6 ">
        <div className="box_jour border-4 border-[#EE7924] text-white text-center p-4 w-36 h-32 rounded-md">
          <div id="jour" className="text-5xl font-bold">
            {days}
          </div>
          <span id="jour_label" className="text-2xl">
            {pluralize(parseInt(days), "jour")}
          </span>
        </div>
        <p className="text-6xl font-bold text-white">:</p>
        <div className="box_heure border-4 border-[#EE7924] text-white text-center p-4 w-36 h-32 rounded-md">
          <div id="heure" className="text-5xl font-bold">
            {hours}
          </div>
          <span id="heure_label" className="text-2xl">
            {pluralize(parseInt(hours), "heure")}
          </span>
        </div>
        <p className="text-6xl font-bold text-white">:</p>
        <div className="box_minute border-4 border-[#EE7924] text-white text-center p-4 w-36 h-32 rounded-md">
          <div id="minute" className="text-5xl font-bold">
            {minutes}
          </div>
          <span id="minute_label" className="text-2xl">
            {pluralize(parseInt(minutes), "minute")}
          </span>
        </div>
        <p className="text-6xl font-bold text-white">:</p>
        <div className="box_seconde border-4 border-[#EE7924] text-white text-center p-4 w-36 h-32 rounded-md">
          <div id="seconde" className="text-5xl font-bold">
            {seconds}
          </div>
          <span id="seconde_label" className="text-2xl">
            {pluralize(parseInt(seconds), "seconde")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Countdown;
